import { MarketButton } from '@market/react';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Es2Tracker from 'services/tracking/tracker';
import { unauthenticatedNotYourReceiptUnlinkActionEvent } from 'services/tracking/events';
import { useDispatch } from 'react-redux';
import { openToast } from 'store/toastSlice';
import { useUnlinkAutomaticReceiptMutation } from 'store/query/receipts';

export interface ReceiptData {
  receiptToken: string;
  merchantName: string;
  obfuscatedEmail?: string;
}

interface UnlinkActionProps {
  receiptData: ReceiptData;
}

const UnlinkAction: React.FC<UnlinkActionProps> = (props) => {
  const dispatch = useDispatch();

  const [isLinked, setIsLinked] = useState<boolean>(
    Boolean(props.receiptData?.obfuscatedEmail)
  );

  const [optOut, { isLoading: isOptingOut }] =
    useUnlinkAutomaticReceiptMutation();

  const { t } = useTranslation();

  const unlinkEmail = async () => {
    Es2Tracker.track(unauthenticatedNotYourReceiptUnlinkActionEvent());
    await optOut(props.receiptData.receiptToken)
      .unwrap()
      .then(() => {
        setIsLinked(false);
      })
      .catch(() => {
        dispatch(
          openToast({
            message: 'We were unable to unlink the card.',
            persistent: true,
            variant: 'critical',
          })
        );
      });
  };

  const obfuscatedEmail = props.receiptData.obfuscatedEmail;

  const component = isLinked ? (
    <>
      <div className={'mb-[30px]'}>
        {t('preference.components.notYourReceiptUnlink.paragraph.sorry')}
      </div>

      <MarketButton
        className={'mb-[24px]'}
        rank={'primary'}
        onClick={unlinkEmail}
        {...(isOptingOut && { disabled: true })}
      >
        {t('preference.components.notYourReceiptUnlink.button.unlinkEmail')}
      </MarketButton>
    </>
  ) : (
    <>
      <h2 className={'heading-30'}>
        {t('preference.components.notYourReceiptUnlink.title.turnedOff')}
      </h2>

      <div className={'mb-[30px]'}>
        {obfuscatedEmail && (
          <Trans
            i18nKey="preference.components.notYourReceiptUnlink.paragraph.turnedOff"
            values={{ obfuscatedEmail }}
          >
            You will no longer receive receipts at
            <b className={'text-[#343b42]'}>{{ obfuscatedEmail }}</b>
          </Trans>
        )}
        {!obfuscatedEmail &&
          t(
            'preference.components.notYourReceiptUnlink.paragraph.turnedOffNoEmail'
          )}
      </div>
    </>
  );

  return <>{component}</>;
};

export default UnlinkAction;
