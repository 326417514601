import React, { useEffect } from 'react';
import { Translation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { BrandLogoIcon } from 'svgs';
import RiskCdpClient from 'services/tracking/cdp/clients/risk';

const ReportScam: React.FC = () => {
  const REPORT_MESSAGE_TITLE = 'Report Message';
  const CUSTOMER_COMPLAINT_EVENT_NAME = 'Customer Complaint';
  const search = useLocation().search;
  const history = useHistory();

  document.title = REPORT_MESSAGE_TITLE;
  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    const messageId = searchParams.get('messageId');
    const reportId = searchParams.get('reportId');
    const trackEvent = searchParams.get('trackEvent') === 'true';
    searchParams.delete('messageId');
    searchParams.delete('reportId');
    searchParams.delete('trackEvent');
    history.replace({ search: searchParams.toString() });
    if (reportId && messageId && trackEvent) {
      const complaintId = `${messageId}-${reportId}`;
      const complainedAtMillis = Date.now();
      const contactId = atob(reportId);
      RiskCdpClient.track(CUSTOMER_COMPLAINT_EVENT_NAME, {
        complained_at_millis: complainedAtMillis,
        complained_entity_id: messageId,
        complaint_id: complaintId,
        contact_id: contactId,
      });
    }
  }, [history, search]);

  return (
    <Translation>
      {(t) => (
        <div
          className={
            'px-8 py-8 lg:py-32 flex justify-center justify-items-center justify-self-center items-center'
          }
        >
          <div className={'w-[680px]'}>
            <BrandLogoIcon
              className={'mr-2 w-[87px] h-[22px] justify-left'}
              logoColor="black"
              textColor="black"
            />
            <h2 className={'mt-5'}>
              {t('reportscam.routes.reportscam.thanks')}
            </h2>
            <p className={'text-lg'}>
              {t('reportscam.routes.reportscam.message1')}
            </p>
            <p className={'text-lg'}>
              {t('reportscam.routes.reportscam.message2')}
            </p>
            <p
              className={'text-lg'}
              dangerouslySetInnerHTML={{
                __html: t('reportscam.routes.reportscam.message3', {
                  bold1End: '</span>',
                  bold1Start: '<span class="font-bold">',
                }),
              }}
            />
          </div>
        </div>
      )}
    </Translation>
  );
};

export default ReportScam;
