import 'App.css';
import React, { Suspense, useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { datadogRum } from '@datadog/browser-rum';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useLocation,
} from 'react-router-dom';
import Preference from 'routes/preference';
import { Interstitial as CashCouponBetaInterstitial } from 'routes/cash-coupon-beta';
import CashLinkingProcess from 'routes/cash-linking';
import UnauthenticatedVerifyEmail from 'routes/unauthenticated-verify-email';
import SqLoading from 'components/SqLoading';
import AuthenticatedRoute from 'components/wrappers/AuthenticatedRoute';
import NativeSignIn from 'routes/native-sign-in';
import PrivacyManagement from 'routes/privacy-requests';
import OnFileBuyerDashboard from 'routes/on-file-management';
import Profile from 'routes/profile';
import MerchantPortal from 'routes/merchant-scoped-portal';
import { findMatchingRoutePattern } from 'utils/wildcardPaths';
import { useSelector } from 'react-redux';
import { AnonymousBoolFlag } from 'routes/profile/models/Flags';
import { AppState } from 'store';
import { selectBoolFlag } from 'store/featureSlice';
import LegacyLoyaltyUrlRedirects from 'routes/merchant-scoped-portal/integrations/loyalty/wrappers/LegacyLoyaltyUrlRedirects';
import AddReportReason from 'routes/report-scam/AddReportReason';
import ReportScam from 'routes/report-scam/ReportScam';

const PageViewTracker: React.FC<{ manuallyTrackPageViews: boolean }> = ({
  manuallyTrackPageViews,
}) => {
  const location = useLocation();

  useEffect(() => {
    const matchingRoutePattern = findMatchingRoutePattern(location.pathname);
    // Allows us to track missing routes before enabling FF rollout
    // Note: This will capture a mix of both valid routes we haven't registered and simply invalid routes (e.g. manually entered URLs)
    if (!matchingRoutePattern) {
      Sentry.captureMessage(
        `Unable to wildcard path for ${location.pathname}. Please add route pattern to wildcardPaths.ts`
      );
    }
    // Given that we won't enable the flag until we're confident we have a complete route list,
    // we could opt to only start the view if we have a matching route pattern.
    // However, we may want to track views for missing routes as well, so we'll start the view regardless.
    if (manuallyTrackPageViews) {
      // Start the event with the raw path - wildcarding is performed in the beforeSend hook
      datadogRum.startView(location.pathname);
    }
  }, [location, manuallyTrackPageViews]);

  return null;
};

const App: React.FC = () => {
  const manuallyTrackPageViews = useSelector((state: AppState) =>
    selectBoolFlag(state, AnonymousBoolFlag.manuallyTrackPageViews)
  );
  return (
    <Router>
      <div className={'flex flex-col max-w-full min-h-screen p-0'}>
        {/* TODO: Remove this Suspense boundary - we don't have suspense-aware data sources so this never triggers */}
        <Suspense
          fallback={
            <div
              className={
                'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
              }
            >
              <SqLoading />
            </div>
          }
        >
          <PageViewTracker manuallyTrackPageViews={manuallyTrackPageViews} />
          <Switch>
            <Route path={'/manage'} component={Preference} />
            <Route path={'/privacyrequests'} component={PrivacyManagement} />
            <Route path={'/signin'} component={NativeSignIn} />
            <Route
              path={'/link_coupon/:cashLinkToken/link'}
              component={CashLinkingProcess}
            />
            <Route
              path={'/link_coupon/:cashLinkToken'}
              component={CashCouponBetaInterstitial}
            />
            <Route
              path={'/v2_link_coupon/:cashLinkToken/link'}
              component={CashLinkingProcess}
            />
            <Route
              path={'/v2_link_coupon/:cashLinkToken'}
              component={CashCouponBetaInterstitial}
            />
            <Route
              exact
              path={'/on-file-management/:baToken'}
              component={OnFileBuyerDashboard}
            />
            <Route
              path={'/verify_email/:verifyEmailToken'}
              component={UnauthenticatedVerifyEmail}
            />
            <Route path={'/report-message'} component={AddReportReason} />
            <Route path={'/report-message-thanks'} component={ReportScam} />
            <Route
              path="/merchantportal/:merchantId"
              component={MerchantPortal}
            />
            <Route
              path={[
                '/loyalty',
                '/loyalty-stars',
                '/loyalty-status',
                '/outreach/welcome',
              ]}
              component={LegacyLoyaltyUrlRedirects}
            />
            <AuthenticatedRoute path={'/'} component={Profile} />
          </Switch>
        </Suspense>
      </div>
    </Router>
  );
};

export default App;
