import { MarketButton, MarketCheckbox, MarketRow } from '@market/react';
import { Translation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { BrandLogoIcon } from 'svgs';
import RiskCdpClient from 'services/tracking/cdp/clients/risk';
import React, { useEffect, useState } from 'react';
import type { MarketRowCustomEvent } from '@market/web-components';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import { selectBoolFlag } from 'store/featureSlice';
import { AnonymousBoolFlag } from 'routes/profile/models/Flags';
import GlobalLoader from 'routes/profile/common/loading/GlobalLoader';

interface Option {
  id: number;
  isSelected: boolean;
}

export const CUSTOMER_COMPLAINT_EVENT_NAME = 'Customer Complaint';

const AddReportReason: React.FC = () => {
  document.title = 'Report Message';

  const history = useHistory();
  const [options, setOptions] = useState<Option[]>([
    { id: 1, isSelected: false },
    { id: 2, isSelected: false },
    { id: 3, isSelected: false },
    { id: 4, isSelected: false },
    { id: 5, isSelected: false },
    { id: 6, isSelected: false },
  ]);
  const search = useLocation().search;
  const [messageId, setMessageId] = useState<string | null>(null);
  const [reportId, setReportId] = useState<string | null>(null);

  const areAnonymousFlagsLoaded = useSelector(
    (state: AppState) => state.feature.areAnonymousFlagsLoaded
  );

  const addReportReasonFlag = useSelector((state: AppState) =>
    selectBoolFlag(state, AnonymousBoolFlag.addReportReason)
  );

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    const messageId = searchParams.get('messageId');
    const reportId = searchParams.get('reportId');
    searchParams.delete('messageId');
    searchParams.delete('reportId');
    if (addReportReasonFlag) {
      setMessageId(messageId);
      setReportId(reportId);
      history.replace({ search: searchParams.toString() });
    } else {
      history.push(
        `/report-message-thanks?messageId=${messageId}&reportId=${reportId}&trackEvent=true`
      );
    }
    // This is necessary to keep the same behavior shown in ReportScam,
    // where the message and report IDs should not be kept in the URL.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = () => {
    if (reportId && messageId) {
      const complaintId = `${messageId}-${reportId}`;
      const complainedAtMillis = Date.now();
      const contactId = atob(reportId);
      RiskCdpClient.track(CUSTOMER_COMPLAINT_EVENT_NAME, {
        complained_at_millis: complainedAtMillis,
        complained_entity_id: messageId,
        complaint_id: complaintId,
        complaint_reason: options.filter((o) => o.isSelected).map((o) => o.id),
        contact_id: contactId,
      });
    }
    history.push('/report-message-thanks');
  };

  const handleRowChange = (event: MarketRowCustomEvent<{ value: string }>) => {
    const id = Number.parseInt(event.target.id, 10);
    setOptions((prevOptions) =>
      prevOptions.map((option) =>
        option.id === id
          ? { ...option, isSelected: !option.isSelected }
          : option
      )
    );
  };

  if (!areAnonymousFlagsLoaded) {
    return <GlobalLoader />;
  }

  return (
    <Translation>
      {(t) => (
        <div
          className={
            'px-8 py-8 lg:py-32 flex justify-center justify-items-center justify-self-center items-center'
          }
        >
          <div className="w-[680px]">
            <BrandLogoIcon
              className="mr-2 w-[87px] h-[22px] justify-left"
              logoColor="black"
              textColor="black"
            />
            <h2 className="mt-5">
              {t('reportscam.routes.additional.information.header')}
            </h2>
            <p className="text-lg">
              {t('reportscam.routes.additional.information.message1')}
            </p>
            <p className="text-lg">
              {t('reportscam.routes.additional.information.message2')}
            </p>
            {options.map((o) => (
              <MarketRow
                key={o.id.toString()}
                id={o.id.toString()}
                controlPosition="leading"
                onMarketRowSelected={handleRowChange}
                onMarketRowDeselected={handleRowChange}
                interactive
                data-testid={`reason-row-${o.id}`}
              >
                <label>
                  <b>
                    {t(
                      `reportscam.routes.additional.information.option${o.id}`
                    )}
                  </b>
                </label>
                <MarketCheckbox slot="control" />
              </MarketRow>
            ))}
            <MarketButton
              disabled={options.every((o) => !o.isSelected)}
              onClick={handleClick}
              data-testid={'add-reason-button'}
            >
              {t('common.submit')}
            </MarketButton>
          </div>
        </div>
      )}
    </Translation>
  );
};

export default AddReportReason;
